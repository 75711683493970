<template>
  <v-container>
    <v-btn right @click="nwUsers()">
      <v-icon left>mdi-account-plus-outline</v-icon>usuario
    </v-btn>
    <user-dialog ref="nwuser" />
    <v-data-table :items="users" :headers="headers" item-key="id">
      <template v-slot:item="{ item }">
        <tr>
          <td>
            <v-icon class="mr-2" @click="editUser(item.USERNAME)">
              mdi-account-edit-outline
            </v-icon>
          </td>
          <td>{{ item.NOMBRE }}</td>
          <td>{{ item.USERNAME }}</td>
          <td>
            <v-select
              v-model="item.PLANTA"
              multiple
              readonly
              :items="item.PLANTA"
            />
          </td>
          <td>
            <v-select
              v-model="item.PERFIL"
              multiple
              readonly
              :items="item.PERFIL"
            />
          </td>
        </tr> </template
    ></v-data-table>
  </v-container>
</template>

<script>
import AdminDataService from "../../services/AdminDataService";
import UserDlg from "./User.vue";
export default {
  name: "clasif",
  components: {
    userDialog: UserDlg,
  },
  data: () => ({
    users: [],
    headers: [
      { text: "", value: "actions", align: "center", sortable: false },
      { text: "Nombre", value: "NOMBRE", align: "start", sortable: false },
      { text: "Usuario", value: "USERNAME", align: "start", sortable: false },
      { text: "Planta", value: "PLANTA", align: "start", sortable: false },
      { text: "Perfil", value: "PERFIL", align: "start", sortable: false },
    ],
  }),
  methods: {
    getUsers() {
      AdminDataService.getUsers().then((response) => {
        this.users = response.data.map(this.getUser);
      });
    },
    getUser(users) {
      return {
        id: users.id,
        NOMBRE: users.nombre,
        PLANTA: users.planta,
        USERNAME: users.username,
        PERFIL: users.roles.map((rol) => rol.name),
      };
    },
    async editUser(id) {
      this.id = id;
      await this.$refs.nwuser.open(id);
    },
    async nwUsers() {
      await this.$refs.nwuser.open();
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>

<template>
  <v-dialog v-model="dialog" :key="id" max-width="1200px" height="500px">
    <v-card class="pa-4" color="white" outlined>
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="nombre"
            label="Nombre"
            :readonly="!opt1"
            prepend-inner-icon="mdi-account-hard-hat"
            required
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="username"
            label="Nombre usuario"
            :readonly="!opt1"
            prepend-inner-icon="mdi-account"
            required
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="email"
            label="Correo"
            :rules="[rules.email]"
            :readonly="!opt1"
            prepend-inner-icon="mdi-email-outline"
            required
          />
        </v-col>
        <v-col class="d-flex" cols="12" md="3">
          <v-select
            v-model="planta"
            :items="plantas"
            :readonly="!opt1"
            multiple
            prepend-inner-icon="mdi-office-building-marker-outline"
            label="Planta"
          />
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col class="d-flex" cols="12" md="3">
          <v-select
            v-model="perfil"
            :items="perfiles"
            :readonly="!opt1"
            multiple
            prepend-inner-icon="mdi-account-lock-outline"
            label="Tipo Perfil"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="contraseña"
            prepend-inner-icon="mdi-key-variant"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            label="Contraseña"
            @click:append="show1 = !show1"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="contraseña1"
            prepend-inner-icon="mdi-key-variant"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            label="Repetir contraseña"
            @click:append="show1 = !show1"
          />
        </v-col>
        <v-col cols="12" md="3"> </v-col>
      </v-row>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn v-if="!opt1" plain @click="btn_del()">eliminar usuario</v-btn>
        <v-btn plain @click="btn_act()">{{ btn_text }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AdminDataService from "../../services/AdminDataService";
export default {
  name: "clasif",
  components: {},
  data: () => ({
    id: "",
    dialog: false,
    show1: false,
    opt1: true,
    plantas: [
      { text: "Paico", value: "paico" },
      { text: "Ochagavía", value: "ochagavia" },
      { text: "Arica", value: "arica" },
      { text: "Tecnologia", value: "montina" },
      { text: "Fábrica", value: "fabrica" },
    ],
    perfiles: [
      { text: "Basico", value: "usuario" },
      { text: "Externo", value: "tercero" },
      { text: "Administrador", value: "supervisor" },
    ],
    nombre: "",
    username: "",
    perfil: [],
    email: "",
    contraseña: "",
    contraseña1: "",
    planta: [],
    rules: {
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Correo inválido.";
      },
    },
  }),
  computed: {
    btn_text: function() {
      return this.opt1 ? "Crear Usuario" : "Actualizar";
    },
    val_pass: function() {
      if (this.contraseña != "" && this.contraseña === this.contraseña1) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    open: function(id) {
      if (id) {
        this.opt1 = false;
        this.id = id;
        AdminDataService.getUser(id).then((response) => {
          this.nombre = response.data.nombre;
          this.username = response.data.username;
          this.email = response.data.email;
          this.planta = response.data.planta;
          this.perfil = response.data.roles;
        });
        this.dialog = true;
      } else {
        this.opt1 = true;
        this.dialog = true;
        this.nombre = null;
        this.username = null;
        this.email = null;
        this.planta = null;
        this.perfil = null;
      }
    },
    btn_act() {
      if (this.opt1) {
        if (this.val_pass) {
          let data = {};
          data["username"] = this.username;
          data["nombre"] = this.nombre;
          data["email"] = this.email;
          data["password"] = this.contraseña;
          data["planta"] = this.planta;
          data["roles"] = this.perfil;
          AdminDataService.newUser(data).then((response) => {
            console.log(response.data);
            this.dialog = false;
          });
        }
      } else {
        console.log("actualizar");
      }
    },
    btn_del() {
      let data = {};
      data["username"] = this.username;
      AdminDataService.deleteUser(data).then((response) => {
        console.log(response.data);
      });
    },
  },
};
</script>
